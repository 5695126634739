import {LocationQuery} from "vue-router";
import {getOriginDomain} from "@/utils";
import {originsConf} from "@/originsConf";

window.ymData = {
    ids: new Set,
    clientId: ""
}

const initConfig = (config: { [key: string]: unknown }) => {
    if (typeof config.id != 'string' || config.id.trim() == '') return;

    window.ymData.ids.add(config.id)
    if (typeof window.ym === 'function') {
        try {
            window.ym(config.id, 'init', config)
            // getClientID будет общий для разных счетчиков, определяется по домену страницы
            window.ym(config.id, 'getClientID', (clientID: string) => {
                window.ymData.clientId = clientID.toString()
            })
        } catch (e) {
            console.error(e)
        }
    }
}


export const ymInitByID = (id: string) => {
    initConfig({
        id: id,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        ecommerce: "dataLayer",
        //webvisor: true,
        //trackHash: true
    })
}

// Яндекс Метрика - инициируется с конфига ориджина или переданных в виджет параметров
export const ymInitByOrigin = (query: LocationQuery) => {

    // Если нет скрипта яндекса - ничего не делаем
    if (!window.ym) return;

    let ymConfig: { [key: string]: unknown } = {}

    const originDomain = getOriginDomain(query)
    const originDomainConf = originsConf[originDomain]

    if (originDomainConf?.ym) {
        ymConfig = originDomainConf?.ym

    } else {
        for (const key of Object.keys(query))
            if (key.includes('ym_'))
                ymConfig[key.replace('ym_', '')] = query[key]
    }

    if (Object.keys(ymConfig).length)
        initConfig(ymConfig)

    // if (Object.keys(ymConfig).length) {
    //     window.ymData.id = '' + ymConfig.id;
    //     window.ym(ymConfig.id, "init", ymConfig);
    //     window.ym(ymConfig.id, 'getClientID', (clientID: string) => {
    //         window.ymData.clientId = clientID.toString();
    //     })
    // }

}

