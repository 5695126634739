export function redirect(url) {

    return !!window.open(url, '_blank')

    // Работает, но надо буде тувеличить высотку iframe и ссылки вернуться в магазин откроются внутри iframe
    // try {
    //     window.location.href = url
    //     return true
    //
    // } catch (e) {
    //     console.error('Браузер заблокировал редирект URL:', e)
    //     return !!window.open(url, '_blank')
    // }

}

export function closeWidget() {
    // К паренту нельзя обращаться, будет исключение
    //console.log('window.parent.origin', window.parent.origin)

    // Если в iFrame
    if (window.parent)
        window.parent?.postMessage('chTicketWidget.close','*')
    // Если прямая ссылка
    //else


}

// Функция для очистки URL и получения только корневого домена без порта
export function getRootDomain(domain: string) {
    // Убираем "https://", "http://", "www.", а также порт (например, :8080)
    const cleanDomain = domain.replace(/^https?:\/\//, '')
        .replace(/^www\./, '')
        .split(':')[0]; // Убираем порт

    const domainParts = cleanDomain.split('.');
    if (domainParts.length > 1) {
        return domainParts.slice(-2).join('.'); // Возвращаем только корневой домен (например, site.com)
    }
    return cleanDomain; // В случае если домен не имеет поддоменов
}

// Пробует получить домен родительского сайта, иначе из запроса, иначе undefined-site
export function getOriginDomain(query: object) {

    let domain = ''

    // Проверяем, если страница открыта в iframe
    if (window !== window.top) {
        try {
            // Попытка получить домен родительского сайта через document.referrer
            if (document.referrer)
                domain = getRootDomain(new URL(document.referrer).hostname)


            if (!domain && window.top) {
                // Попытка получить домен через window.top.location (если доступно)
                try {
                    domain = getRootDomain(window.top.location.hostname)
                } catch (e) {
                    // Если не удалось получить домен через window.top.location
                    //console.log('Не удалось получить домен через window.top.location:', e);
                }

                // Еще один способ — получение домена через window.top.document.domain, если разрешено
                try {
                    domain = getRootDomain(window.top.document.domain)
                } catch (e) {
                    // Если не удалось получить домен через window.top.document.domain
                    //console.log('Не удалось получить домен через document.domain:', e);
                }
            }

        } catch (error) {
            //console.log('Ошибка при попытке получить домен родительского сайта:', error);
        }

    }
    //else        return "" // Страница не открыта в iframe


    // если не получилось (cors) тогда опираемся на переданный в url (прямая ссылка или скрипт октрытия виджета)
    if (!domain )
        domain = query['domain'] ? query['domain'].toString() : 'undefined-site'

    console.log('Origin:', domain)

    return domain
}

export function consoleLog(...args: unknown[]){
    console.log(...args)
}

export function consoleError(...args: unknown[]){
    console.error(...args)
}
